.header {
  height: 80px;
  padding: 20px 24px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  background: #FFFFFF;
  position: sticky;
  top: 0;
  z-index: 999;

  &.signed {
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
  }

  &-left {
    display: flex;
    flex-direction: row;
  }

  &-center {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .logout {
      font-family: "Favorit";
      cursor: pointer;
      font-style: normal;
      font-weight: 350;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      text-transform: uppercase;
      color: #ff3200;
      margin-right: 32px;
    }
    img {
      width: 48px;
      height: 48px;
      border-radius: 24px;
    }
  }
}

.header img {
  width: 40px;
  height: 40px;
}
